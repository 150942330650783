<template>
  <el-row class="activity page" type="flex" justify="center">
    <el-col class="activity-item" :col="24" v-for="item in activityList" :key="item.id" @click.native="handleActivityClick(item)">
      <el-image lazy class="hitem-img" :src="require(`../assets/image/activity/${item.id}.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>
        </div>
      </el-image>
      <el-image class="activity-item-text" lazy :src="require(`../assets/image/activity/${item.id}-text.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true"></el-image>
      <button class="y-btn" v-if="item.loadingImg"><span>详情</span></button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      activityList: [
        {
          id: 'blybh',
          img: 'blybh',
          loadingImg: false
        },
        {
          id: 'zqjs',
          img: 'zqjs',
          loadingImg: false
        }
      ]
    }
  },
  methods: {
    handleActivityClick(item) {
      this.$router.push({ path: `/news/${item.id}` })
    }
  }
}
</script>
<style lang="scss">
.activity {
  padding: 24px 40px 100px;
  flex-wrap: wrap;
  .activity-item {
    cursor: pointer;
    max-width: 1600px;
    position: relative;
    margin-bottom: 30px;
    .activity-item-text {
      position: absolute;
      left: 0;
      pointer-events: none;
    }
    .y-btn {
      position: absolute;
      bottom: 25%;
      left: 5%;
      background: #fff;
      border-color: #fff;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #3aaa68;
      }
      &:hover {
        background: #3aaa68;
        border-color: #3aaa68;
        span {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .activity {
    padding: 14px 20px 60px;
    flex-wrap: wrap;
    .activity-item {
      margin-bottom: 15px;
      .y-btn {
        padding: 4px;
        border-radius: 26px;
        min-width: 72px;
        bottom: 18%;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
