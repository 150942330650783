<template>
  <div class="activity-detail page">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/news' }">新闻列表</el-breadcrumb-item>
      <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
    </el-breadcrumb>
    <component :is="newsDetail"></component>
  </div>
</template>

<script>
import blybh from './newsDetails/blybh.vue'
import zqjs from './newsDetails/zqjs.vue'
export default {
  name: 'NewsDetail',
  data() {
    return {}
  },
  components: {
    blybh,
    zqjs
  },
  computed: {
    newsDetail() {
      return this.$route.params.id
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.activity-detail {
  width: 1200px;
  margin: 0 auto;
  padding: 80px 60px 100px;
  .title {
    color: #000;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 96px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .subtitle {
    color: #000;
    text-align: center;
    font-family: Source Han Sans CN;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .content {
    margin-top: 90px;
    .hd-zp {
      flex-wrap: wrap;
      .hd-zp-item {
        margin-bottom: 26px;
      }
    }

    .zc-con-title {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin: 80px 0 60px;
      text-align: center;
    }
    .zc-con-text {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 20px 0 40px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .activity-detail {
    width: 100%;
  }
}

/* 小设备（手机，小于 960px) */
@media screen and (max-width: 960px) {
  .activity-detail {
    padding: 40px 30px 50px;
    .a-detail {
      .title {
        font-size: 76px;
      }
      .subtitle {
        font-size: 38px;
      }
      .content {
        margin-top: 60px;
        .zc-con-title {
          font-size: 38px;
          margin: 60px 0 40px;
        }
        .zc-con-text {
          font-size: 18px;
          padding: 16px 0 30px;
        }
      }
    }
  }
}

/* 小设备（手机，小于 960px) */
@media screen and (max-width: 768px) {
  .activity-detail {
    padding: 20px 20px 38px;
    .a-detail {
      .title {
        font-size: 46px;
      }
      .subtitle {
        font-size: 26px;
      }
      .content {
        margin-top: 30px;
        .zc-con-title {
          font-size: 26px;
          margin: 30px 0 20px;
        }
        .zc-con-text {
          font-size: 14px;
          padding: 10px 0 20px;
        }
      }
    }
  }
}
</style>
