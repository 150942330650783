<template>
  <div>
    <h2 class="zc-con-title">text1</h2>
    <p style="color: #000; font-family: Source Han Sans CN; font-size: 20px; font-style: normal; font-weight: 500; line-height: normal">测试数据</p>
  </div>
</template>

<script>
export default {
  name: 'text1s',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
