<template>
  <component :is="showroom"></component>
</template>

<script>
import cszc from './showroom/cszc.vue'
import lszc from './showroom/lszc.vue'
import vryy from './showroom/vryy.vue'
export default {
  name: 'ShowroomList',
  data() {
    return {}
  },
  components: {
    cszc,
    lszc,
    vryy
  },
  computed: {
    showroom() {
      return this.$route.params.id
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.zc {
  .zc-top {
    position: relative;
    .title-box {
      position: absolute;
      padding-top: 8%;
      display: flex;
      justify-content: center;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      .title {
        width: 671px;
        height: 314px;
      }
    }
  }
  .zc-list {
    padding: 32px 40px 100px;
    max-width: 1680px;
    margin: 0 auto;
    flex-wrap: wrap;
    .zc-list-box {
      padding-top: 30px;
    }
    .zc-item {
      cursor: pointer;
      max-width: 1600px;
      position: relative;
      margin-bottom: 30px;
      .zc-item-text {
        position: absolute;
        left: 0;
        pointer-events: none;
      }
      .y-btn {
        position: absolute;
        bottom: 25%;
        left: 5%;
        background: #fff;
        border-color: #fff;
        span {
          font-size: 16px;
          font-weight: 500;
          color: #3aaa68;
        }

        &:hover {
          background: #3aaa68;
          border-color: #3aaa68;
          span {
            color: #fff;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .zc {
    .zc-top {
      margin-top: 50px;
      .title-box {
        .title {
          width: 480px;
          height: auto;
        }
      }
    }
    .zc-list {
      padding: 25px 30px 80px;

      .zc-list-box {
        padding-top: 25px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .zc {
    .zc-top {
      .title-box {
        .title {
          width: 240px;
          height: auto;
        }
      }
    }
    .zc-list {
      padding: 12px 20px 40px;

      .zc-list-box {
        padding-top: 12px;
      }
      .zc-item {
        margin-bottom: 20px;
        .y-btn {
          padding: 4px;
          border-radius: 26px;
          min-width: 72px;
          bottom: 18%;
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
