<template>
  <div class="a-detail">
    <p class="title">商汤科技</p>
    <p class="subtitle">硬核科技</p>
    <div class="content">
      <p class="zc-con-text">
        作为全球领先的⼈⼯智能平台公司，商汤科技Sense-Time是中国科技部指定的“智能视觉”国家新⼀代⼈⼯智能开放创新平台。商汤科技以“坚持原创，让Al引领⼈类进步”为使命和愿景。公司⾃主研发并建⽴了全球顶级的深度学习平台和超算中⼼，推出了⼀系列领先的⼈⼯智能技术。商汤科技已成为亚洲领先的AI算法提供商。世界正在以前所未有的速度改变，Al已逐渐融⼊⽣活的⽅⽅⾯⾯。⽆论孩⼦们未来⾝处哪⼀种⾏业A!都将成为他们⽣活和⼯作中不可或缺的⼯具。
      </p>
      <el-image lazy :src="require('../../assets/image/newsDetail/hdtp.png')" style="width: 100%"></el-image>
      <p class="zc-con-text">
        本 次 社 会 ⻅ 学 活 动 ， L i n k 将 带 孩 ⼦ 们 来 到 全 球 领 先 的 ⼈ ⼯ 智 能 平 台 公 司 - - 商 汤 科 技 ， 了 解 A I 及 A l 在 不 同 ⾏ 业 的 应 ⽤ ， 体 验 ⼈ ⼯ 智 能 的 游 戏 ， 让
        他 们 可 以 提 前 思 考 未 来 学 什 么 ， 让 他 们 的 ⽣ 活 和 未 来 更 加 的 多 元 化 。
      </p>
      <h2 class="zc-con-title">实 际 能 力 发 展 体 验</h2>
      <p class="zc-con-text">
        1.参观商汤科技A I未来世界展 厅 ， 验 商汤最前沿的⼈⼯智能技术和产品应⽤ 。
        <br />
        2.商汤科技⼯程师分享如何从" 玩 "中产⽣科学项⽬ 。
        <br />
        3.孩⼦们⽤编程⼩⻋进⾏电机控制与传感器试验 ， 模拟智慧城市中多⻋协同调度和⾃动驾驶等场景。
      </p>
      <div style="border-radius: 20px; background: #3aaa68; height: 403px; display: flex; align-items: center; justify-content: center">
        <p style="color: #fff; font-family: Source Han Sans CN; font-size: 48px; font-style: normal; font-weight: 900; line-height: normal">这个是行程安排</p>
      </div>
      <h2 class="zc-con-title">活动现场照片</h2>
      <el-row class="hd-zp" :gutter="26">
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/newsDetail/hdzp1.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/newsDetail/hdzp2.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/newsDetail/hdzp3.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/newsDetail/hdzp4.png')" style="width: 100%; height: auto; margin-bottom: 26px"></el-image>
          <el-image lazy :src="require('../../assets/image/newsDetail/hdzp5.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blybh',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
