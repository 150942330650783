<template>
  <div class="activity-detail page">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/activity' }">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <component :is="activityDetail"></component>
  </div>
</template>

<script>
import blybh from './details/blybh.vue'
import zqjs from './details/zqjs.vue'
export default {
  name: 'ActivityDetail',
  data() {
    return {}
  },
  components: {
    blybh,
    zqjs
  },
  computed: {
    activityDetail() {
      return this.$route.params.id
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
