<template>
  <div>
    <h2 class="zc-con-title">作品介绍</h2>
    <p class="zc-con-text">
      作 为 全 球 领 先 的 ⼈ ⼯ 智 能 平 台 公 司 ， 商 汤 科 技 S e n s e - T i m e 是 中 国 科 技 部 指 定 的 “ 智 能 视 觉 ” 国 家 新 ⼀ 代 ⼈ ⼯ 智 能 开 放 创 新 平 台 。 商 汤 科 技 以 “ 坚 持
      原 创 ， 让 A l 引 领 ⼈ 类 进 步 ” 为 使 命 和 愿 景 。 公 司 ⾃ 主 研 发 并 建 ⽴ 了 全 球 顶 级 的 深 度 学 习 平 台 和 超 算 中 ⼼ ， 推 出 了 ⼀ 系 列 领 先 的 ⼈ ⼯ 智 能 技 术 。 商 汤 科
      技 已 成 为 亚 洲 领 先 的 A I 算 法 提 供 商 。 世 界 正 在 以 前 所 未 有 的 速 度 改 变 ， A l 已 逐 渐 融 ⼊ ⽣ 活 的 ⽅ ⽅ ⾯ ⾯ 。 ⽆ 论 孩 ⼦ 们 未 来 ⾝ 处 哪 ⼀ 种 ⾏ 业 A ! 都 将 成 为
      他 们 ⽣ 活 和 ⼯ 作 中 不 可 或 缺 的 ⼯ 具 。
    </p>
    <el-image lazy :src="require('../../../../assets/image/showroom/cszc01-1.png')" style="width: 100%"></el-image>
    <p class="zc-con-text">
      本 次 社 会 ⻅ 学 活 动 ， L i n k 将 带 孩 ⼦ 们 来 到 全 球 领 先 的 ⼈ ⼯ 智 能 平 台 公 司 - - 商 汤 科 技 ， 了 解 A I 及 A l 在 不 同 ⾏ 业 的 应 ⽤ ， 体 验 ⼈ ⼯ 智 能 的 游 戏 ， 让 他
      们 可 以 提 前 思 考 未 来 学 什 么 ， 让 他 们 的 ⽣ 活 和 未 来 更 加 的 多 元 化 。
    </p>
    <h2 class="zc-con-title">数学原理</h2>
    <p class="zc-con-text">
      作 为 全 球 领 先 的 ⼈ ⼯ 智 能 平 台 公 司 ， 商 汤 科 技 S e n s e - T i m e 是 中 国 科 技 部 指 定 的 “ 智 能 视 觉 ” 国 家 新 ⼀ 代 ⼈ ⼯ 智 能 开 放 创 新 平 台 。 商 汤 科 技 以 “ 坚 持
      原 创 ， 让 A l 引 领 ⼈ 类 进 步 ” 为 使 命 和 愿 景 。 公 司 ⾃ 主 研 发 并 建 ⽴ 了 全 球 顶 级 的 深 度 学 习 平 台 和 超 算 中 ⼼ ， 推 出 了 ⼀ 系 列 领 先 的 ⼈ ⼯ 智 能 技 术 。 商 汤 科
      技 已 成 为 亚 洲 领 先 的 A I 算 法 提 供 商 。 世 界 正 在 以 前 所 未 有 的 速 度 改 变 ， A l 已 逐 渐 融 ⼊ ⽣ 活 的 ⽅ ⽅ ⾯ ⾯ 。 ⽆ 论 孩 ⼦ 们 未 来 ⾝ 处 哪 ⼀ 种 ⾏ 业 A ! 都 将 成 为
      他 们 ⽣ 活 和 ⼯ 作 中 不 可 或 缺 的 ⼯ 具 。
    </p>
  </div>
</template>

<script>
export default {
  name: 'sy',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
