import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Activity from '../views/Activity.vue'
import ActivityDetail from '../views/ActivityDetail.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import Shop from '../views/Shop.vue'
import Showroom from '../views/Showroom.vue'
import ShowroomList from '../views/ShowroomList.vue'
import ShowroomDetail from '../views/ShowroomDetail.vue'
import Ticketing from '../views/Ticketing.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity
  },
  {
    path: '/activity/:id',
    name: 'activityDetail',
    component: ActivityDetail
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: NewsDetail
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/showroom',
    name: 'showroom',
    component: Showroom
  },
  {
    path: '/showroom/:id',
    name: 'showroomList',
    component: ShowroomList
  },
  {
    path: '/showroom/:type/:id',
    name: 'showroomDetail',
    component: ShowroomDetail
  },
  {
    path: '/ticketing',
    name: 'ticketing',
    component: Ticketing
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 }
    }
  }
})

export default router
