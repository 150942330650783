<template>
  <div>
    <h2 class="zc-con-title">text2</h2>
    <p class="zc-con-text">测试数据</p>
    <el-image lazy :src="require('../../../../assets/image/showroom/cszc01-1.png')" style="width: 100%"></el-image>
    <p style="color: #000; font-family: Source Han Sans CN; font-size: 20px; font-style: normal; font-weight: 500; line-height: normal; margin-top: 35px">测试数据</p>
    <h2 class="zc-con-title">测试数据</h2>
    <p class="zc-con-text">测试数据</p>
  </div>
</template>

<script>
export default {
  name: 'sy',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
