<template>
  <el-container direction="vertical">
    <Header />
    <router-view />
    <Footer />
  </el-container>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {
  name: 'Page',
  components: {
    Header,
    Footer
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.page {
  margin-top: 82px;
}
.image-slot {
  width: 100%;
  background: #f6f6f6;
  height: 40vw;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-icon-loading {
    font-size: 30px;
    color: #cbcbcb;
  }
}

/* 超小设备（手机，小于 768px) */
@media screen and (max-width: 768px) {
  .page {
    margin-top: 50px;
  }

  .image-slot {
    height: 25vh;
    border-radius: 6px;
    .el-icon-loading {
      font-size: 24px;
    }
  }
}
</style>
