<template>
  <div class="zc">
    <div class="zc-top">
      <el-image lazy :src="require(`../../../../assets/image/showroom/${cszcDetail}.png`)" style="width: 100%; height: auto"></el-image>
      <div class="title-box">
        <el-image lazy class="title" :src="require('../../../../assets/image/showroom/lszc-title.png')"></el-image>
      </div>
    </div>
    <div class="zc-con">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/showroom' }">展陈列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/showroom/lszc' }">临时展陈</el-breadcrumb-item>
        <el-breadcrumb-item>展陈详情</el-breadcrumb-item>
      </el-breadcrumb>
      <component :is="cszcDetail"></component>
    </div>
  </div>
</template>

<script>
import text2 from './text2.vue'
import text1 from './text1.vue'
export default {
  name: 'lszcIndex',
  data() {
    return {}
  },
  components: {
    text2,
    text1
  },
  computed: {
    cszcDetail() {
      return this.$route.params.id
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
