<template>
  <div class="shop page">
    <div class="shop-con">
      <el-image lazy :src="require('../assets/image/banner-tips.png')" class="shop-tips"></el-image>
      <el-row class="title" type="flex" justify="center">
        <div style="display: inline-block; position: relative">
          <el-image lazy :src="require('../assets/image/wcsd.png')" class="wcsd"></el-image>
          <div class="logohei-box">
            <el-image lazy :src="require('../assets/image/logohei.png')" class="logohei"></el-image>
          </div>
        </div>
      </el-row>
      <el-row class="shop-list" :gutter="50" type="flex">
        <el-col :xs="12" :sm="12" :lg="8" class="shop-item" v-for="item in shopList" :key="item.id">
          <div class="shop-item-box" v-on:click="lookErm(item.codeImg)">
            <el-image lazy class="hitem-img" :src="require(`../assets/image/shop/${item.img}.png`)" style="width: 100%; height: auto">
              <div slot="placeholder" class="image-slot">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <p class="shop-item-title">{{ item.name }}</p>
            <p class="shop-item-con">{{ item.tips }}</p>
          </div>
        </el-col>
      </el-row>
      <el-dialog :visible.sync="ermDialog" center :show-close="false" custom-class="ewm-dialog">
        <el-image v-if="ermImg" :src="require(`../assets/image/shop/${ermImg}.png`)" class="ewm-dialog-img"></el-image>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shop',
  data() {
    return {
      ermDialog: false,
      ermImg: '',
      shopList: [
        {
          id: '001',
          img: 'spitem1',
          codeImg: 'ds2',
          name: '卡套/NFT头像贴纸',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月更新一次.'
        },
        {
          id: '002',
          img: 'spitem2',
          codeImg: 'ew1',
          name: '托特包',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月更新一次.'
        },
        {
          id: '003',
          img: 'spitem3',
          name: '托特包',
          codeImg: 'ewm1',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内。'
        },
        {
          id: '004',
          img: 'spitem4',
          name: '徽章',
          codeImg: 'ewm1',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月更新一次.'
        },
        {
          id: '005',
          img: 'spitem5',
          name: '徽章',
          codeImg: 'ew1',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月更新一次.'
        },
        {
          id: '006',
          img: 'spitem6',
          name: '胶带',
          codeImg: 'sp-code',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内。'
        },
        {
          id: '007',
          img: 'spitem7',
          name: 'T恤',
          codeImg: 'ewm1',
          tips: '这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月更新一次.'
        }
      ]
    }
  },
  methods: {
    lookErm(imgName) {
      this.ermDialog = true
      this.ermImg = imgName
    }
  }
}
</script>

<style lang="scss">
.shop {
  padding: 60px 120px;
  .shop-con {
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .shop-tips {
      width: 100%;
      max-width: 1446px;
      margin: 0 auto;
    }
    .title {
      align-items: center;
      margin-top: 30px;
      .wcsd {
        width: 96%;
      }
      .logohei-box {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 100%;
        .logohei {
          width: 109px;
          margin-left: 50px;
        }
      }
    }
    .shop-list {
      flex-wrap: wrap;
      margin-top: 80px;

      .image-slot {
        height: 20vw;
      }
      .shop-item {
        font-family: Source Han Sans CN;
        font-style: normal;
        line-height: normal;
        margin-bottom: 80px;
        .shop-item-box {
          cursor: pointer;
        }
        .shop-item-title {
          color: #000;
          font-size: 36px;
          font-weight: 700;
          margin-top: 20px;
        }
        .shop-item-con {
          color: #797b83;
          font-size: 15px;
          font-weight: 400;
          margin-top: 10px;
          height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .ewm-dialog {
    width: 560px;
    background: transparent;
    box-shadow: none;
    pointer-events: none;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      justify-content: center;
      .ewm-dialog-img {
        max-width: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .shop {
    padding: 40px 100px 60px;
  }
  .shop .shop-con .title .wcsd {
    width: 92%;
  }
  .shop .shop-con .title .logohei-box .logohei {
    width: 80px;
    margin-left: -20px;
  }
}

@media screen and (max-width: 960px) {
  .shop .shop-con .shop-list {
    margin-top: 50px;
    .image-slot {
      height: 24vh;
    }
  }
  .shop .shop-con .shop-list .shop-item .shop-item-title {
    font-size: 30px;
  }
  .shop .shop-con .shop-list .shop-item .shop-item-con {
    font-size: 14px;
  }
  .shop .shop-con .shop-list .shop-item {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .shop {
    padding: 24px 20px 50px;
    .ewm-dialog {
      width: 100%;
      padding: 15px;
    }
  }
  .shop .shop-con .title .wcsd {
    width: 92%;
  }
  .shop .shop-con .title > div {
    display: flex !important;
    justify-content: center;
  }
  .shop .shop-con .title .logohei-box .logohei {
    width: 40px;
    margin-left: -10px;
  }
  .shop .shop-con .shop-list {
    margin-top: 40px;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .shop .shop-con .shop-list .shop-item .shop-item-title {
    font-size: 18px;
    margin-top: 14px;
  }
  .shop .shop-con .shop-list .shop-item {
    margin-bottom: 30px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .shop .shop-con .title .logohei-box {
    width: 34px;
    top: -20px;
    left: 92%;
  }
}
</style>
