<template>
  <div class="ticketing page">
    <div class="banner">
      <div class="banner-con">
        <el-image lazy :src="require('../assets/image/banner-tips.png')" class="img-tips-lg" style="width: 100%"></el-image>
        <el-image lazy :src="require('../assets/image/banner-tips-xs.png')" class="img-tips-xs" style="width: 100%"></el-image>
        <div class="banner-row">
          <el-image lazy :src="require('../assets/image/banner-img.png')" class="banner-imgx"></el-image>
          <div class="sm-box-con">
            <div class="sm-box">
              <p class="title">
                请使用微信扫描下方二维码，
                <br />
                进入小程序购票
              </p>
              <el-image lazy :src="require('../assets/image/erm.png')" class="erm"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ticketing'
}
</script>
<style lang="scss">
.ticketing {
  .banner {
    padding-top: 72px;
    border-radius: 0;
    background: #faca21;
    .banner-row .banner-imgx {
      width: 55%;
      margin-top: 30px;
    }
  }
  .sm-box-con {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .sm-box {
    padding: 40px 36px 100px;
    background: #fff;
    border-radius: 20px;
    .title {
      color: #000;
      text-align: center;
      font-family: Source Han Sans CN;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .erm {
      width: 100%;
      margin-top: 62px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .ticketing {
    .sm-box {
      width: 100%;
      .title {
        font-size: 18px;
      }
      padding: 30px 30px 70px;
      .erm {
        margin-top: 32px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .ticketing {
    .banner {
      padding-top: 32px;
    }
    .sm-box {
      padding-bottom: 50px;
    }
    .sm-box-con {
      width: 65%;
    }
    .banner .banner-row {
      flex-direction: column-reverse;
      align-items: center;
      .banner-imgx {
        width: 65%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .ticketing {
    .banner {
      padding-top: 16px;
    }
    .sm-box {
      .title {
        font-size: 12px;
      }
      .erm {
        margin-top: 10px;
      }
    }
  }
}
</style>
