<template>
  <el-row class="showroom page" type="flex" justify="center">
    <el-col class="showroom-item" :col="24" v-for="item in showroomList" :key="item.id" @click.native="handleShowroomClick(item)">
      <el-image lazy :src="require(`../assets/image/${item.img}.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>
        </div>
      </el-image>
      <button :class="['y-btn', item.id]" v-if="item.loadingImg"><span>详情</span></button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Showroom',
  data() {
    return {
      showroomList: [
        {
          id: 'cszc',
          img: 'cgzcbig',
          loadingImg: false
        },
        {
          id: 'lszc',
          img: 'lszcbig',
          loadingImg: false
        },
        {
          id: 'vryy',
          img: 'vryybig',
          loadingImg: false
        }
      ]
    }
  },
  methods: {
    handleShowroomClick(item) {
      this.$router.push({ path: `/showroom/${item.id}` })
    }
  }
}
</script>
<style lang="scss">
.showroom {
  padding: 24px 40px 135px;
  flex-wrap: wrap;
  .showroom-item {
    cursor: pointer;
    max-width: 1600px;
    position: relative;
    margin-bottom: 30px;
    // .showroom-item-text {
    //   position: absolute;
    //   left: 0;
    //   pointer-events: none;
    // }
    .y-btn {
      position: absolute;
      bottom: 28%;
      left: 53%;
      &:hover {
        background-color: #000;
        span {
          color: #ed954f;
        }
        &.vryy {
          span {
            color: #5bacea;
          }
        }
        &.lszc {
          span {
            color: #ee7655;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .showroom {
    padding: 14px 20px 60px;
    flex-wrap: wrap;
  }
  .showroom {
    .showroom-item {
      margin-bottom: 15px;
      .y-btn {
        padding: 4px;
        border-radius: 26px;
        min-width: 72px;
        bottom: 18%;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
