<template>
  <div>
    <h2 class="zc-con-title">text1</h2>
    <p class="zc-con-text">测试数据</p>
  </div>
</template>

<script>
export default {
  name: 'cszc02',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
