<template>
  <el-row class="activity page" type="flex" justify="center">
    <el-col class="activity-item" :col="24" v-for="item in activityList" :key="item.id" @click.native="handleActivityClick(item)">
      <el-image lazy class="hitem-img" :src="require(`../assets/image/activity/${item.id}.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>
        </div>
      </el-image>
      <el-image class="activity-item-text" lazy :src="require(`../assets/image/activity/${item.id}-text.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true"></el-image>
      <button class="y-btn" v-if="item.loadingImg"><span>详情</span></button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Activity',
  data() {
    return {
      activityList: [
        {
          id: 'blybh',
          loadingImg: false
        },
        {
          id: 'zqjs',
          loadingImg: false
        }
      ]
    }
  },
  methods: {
    handleActivityClick(item) {
      this.$router.push({ path: `/activity/${item.id}` })
    }
  }
}
</script>
<style lang="scss"></style>
