<template>
  <el-footer class="footer" height="auto">
    <div class="footer-con">
      <el-image lazy :src="require('../assets/image/footer-tips.png')" class="img-tips-lg" style="width: 100%"></el-image>
      <el-image lazy :src="require('../assets/image/footer-tips-xs.png')" class="img-tips-xs" style="width: 100%; max-width: 784px; margin: 0 auto"></el-image>
      <el-row class="footer-infor">
        <el-col :md="12" :lg="6">详细地址: 重庆市科技馆B区1楼</el-col>
        <el-col :md="12" :lg="5">联系电话: 023-89861666</el-col>
        <el-col :md="12" :lg="8">开馆时间: 上午09:30 - 12:00 下午13:00 - 17:00（国定假日另行通知）</el-col>
        <el-col :md="12" :lg="5" class="footer-infor-last">重庆数知妙悟科技有限公司</el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-image lazy :src="require('../assets/image/footer-image.png')" class="footer-image"></el-image>
      </el-row>
    </div>
  </el-footer>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
@media screen and (max-width: 1200px) {
  .footer-infor {
    .el-col {
      margin-bottom: 10px;
    }
  }
  .footer-infor-last {
    text-align: left !important;
  }
}
.footer {
  background: #020204;
  padding: 20px 50px;
  .footer-con {
    max-width: 1446px;
    margin: 0 auto;
  }
  .footer-infor {
    margin-top: 40px;
    color: #fff;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .footer-infor-last {
      text-align: right;
    }
  }
  .footer-image {
    width: 354px;
    margin: 80px 0 50px;
  }
}
/* 超小设备（手机，小于 1280px) */
@media screen and (max-width: 1280px) {
  .footer {
  }
}

/* 超小设备（手机，小于 768px) */
@media screen and (max-width: 768px) {
  .footer {
    padding: 16px;
    .footer-infor {
      margin-top: 28px;
    }
    .footer-image {
      width: 254px;
      margin: 10px 0;
    }
  }
}
</style>
