<template>
  <el-header class="header" height="auto">
    <a href="/" class="logo-box flex-ac">
      <el-image lazy :src="require('../assets/image/logo.png')" class="img-log"></el-image>
      <el-image lazy class="logo-text" :src="require('../assets/image/logotext.png')"></el-image>
    </a>
    <div class="menu">
      <el-button v-for="item in menu" :key="item.id" @click="handleMenuClick(item)" :class="[$route.name.indexOf(item.id) > -1 ? 'active' : '', item.id, 'menu-item']">{{ item.name }}</el-button>
    </div>
    <div class="header-menu-xs">
      <i class="el-icon-menu" @click="show = !show"></i>
      <transition name="el-zoom-in-top">
        <div v-show="show" class="transition-box">
          <ul>
            <li v-for="item in menu" :key="item.id" @click="handleMenuClick(item)" :class="[$route.name.indexOf(item.id) > -1 ? 'active' : '', item.id, 'menu-item']">{{ item.name }}</li>
          </ul>
        </div>
      </transition>
    </div>
  </el-header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      show: false,
      menu: [
        {
          id: 'home',
          name: '首页'
        },
        // {
        //   id: 'activity',
        //   name: '活动'
        // },
        {
          id: 'showroom',
          name: '展厅'
        },
        // {
        //   id: 'shop',
        //   name: '商店'
        // },
        // {
        //   id: 'news',
        //   name: '新闻'
        // },
        {
          id: 'ticketing',
          name: '购票'
        }
      ]
    }
  },
  methods: {
    handleMenuClick(item) {
      this.show = false
      this.$router.push({ name: item.id })
    }
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 82px !important;
  padding: 15px 68px;
  display: flex;
  justify-content: space-between;
  background: #000;
  border: 1px solid #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  z-index: 99;
  .img-log {
    width: 50px;
    height: 50px;
  }
  .logo-text {
    width: 96.67px;
    height: 40px;
    margin-left: 15px;
  }
  .menu {
    display: flex;
    align-items: center;
    .menu-item + .menu-item {
      margin-left: 25px;
    }
    .menu-item {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 94px;
      border-radius: 37px;
      border-width: 2px;
      border-color: transparent;
      transition: all 0.25s linear;
      span {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #000;
        line-height: 24px;
      }
      &.home {
        background: #ed954f;
        &.active {
          span {
            color: #ed954f;
          }
          border-color: #ed954f;
          background: transparent;
        }
      }
      &.activity {
        background: #3aaa68;
        &.active,
        &:hover {
          span {
            color: #3aaa68;
          }
          border-color: #3aaa68;
          background: transparent;
        }
      }
      &.showroom {
        background: #5bacea;
        &.active,
        &:hover {
          span {
            color: #5bacea;
          }
          border-color: #5bacea;
          background: transparent;
        }
      }
      &.shop {
        background: #ef5e03;
        &.active,
        &:hover {
          span {
            color: #ef5e03;
          }
          border-color: #ef5e03;
          background: transparent;
        }
      }
      &.news {
        background: #ee7655;
        &.active,
        &:hover {
          span {
            color: #ee7655;
          }
          border-color: #ee7655;
          background: transparent;
        }
      }
      &.ticketing {
        background: #275bef;
        &.active,
        &:hover {
          span {
            color: #275bef;
          }
          border-color: #275bef;
          background: transparent;
        }
      }
    }
  }
}
.header-menu-xs {
  display: none;
}

/* 小设备（手机，小于 960px) */
@media screen and (max-width: 960px) {
  .header {
    padding: 15px 20px;
    .menu {
      display: none;
    }
  }
  .header-menu-xs {
    line-height: 44px;
    display: flex;
    align-items: center;
  }

  .header-menu-xs i {
    font-size: 34px;
    color: #fff;
  }

  .transition-box {
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    top: 100%;
    left: 0;
    z-index: 99;
    box-sizing: border-box;
    position: absolute;
  }

  .transition-box ul {
    font-size: 16px;
    line-height: 20px;
    box-shadow: 6px 6px 7px 0px rgba(0, 0, 0, 0.1);
  }

  .transition-box ul li {
    &.home {
      &.active {
        color: #ed954f;
      }
    }
    &.activity {
      &.active,
      &:hover {
        color: #3aaa68;
      }
    }
    &.showroom {
      &.active,
      &:hover {
        color: #5bacea;
      }
    }
    &.shop {
      &.active,
      &:hover {
        color: #ef5e03;
      }
    }
    &.ticketing {
      &.active,
      &:hover {
        color: #275bef;
      }
    }
    padding: 10px;
  }

  .transition-box ul li:last-child {
    border: none;
  }
}

/* 超小设备（手机，小于 768px) */
@media screen and (max-width: 768px) {
  .header {
    height: 50px !important;
    padding: 10px;
    .img-log {
      width: 28px;
      height: 28px;
    }
    .logo-text {
      width: auto;
      height: 28px;
      margin-left: 10px;
    }
  }

  .header-menu-xs i {
    font-size: 26px;
    color: #fff;
  }
}
</style>
