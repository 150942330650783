<template>
  <div class="home">
    <div class="banner">
      <video ref="video" muted loop :class="['banner-video', isLoad ? 'load' : '']" @canplaythrough="getVideo">
        <source :src="require('../assets/video/1.mp4')" type="video/mp4" />
      </video>
      <el-image :src="require('../assets/image/banner.png')" style="width: 100%" :class="[isLoad ? 'banner-hide' : '']"></el-image>
      <el-image :src="require('../assets/image/banner-text.png')" class="banner-img-text"></el-image>
    </div>
    <div class="panel">
      <div class="flex-ac">
        <el-image :src="require('../assets/image/zhanting.png')" class="title-img"></el-image>
        <span class="panel-title">展厅</span>
      </div>
      <el-row class="zt-box" type="flex" justify="center" :gutter="30">
        <el-col :md="24" :lg="12" @click.native="handleZtClick('cszc')">
          <div class="zt-item">
            <el-image :src="require('../assets/image/cszl.png')" style="width: 100%; height: auto"></el-image>
            <button class="y-btn"><span>查看详情</span></button>
          </div>
        </el-col>
        <el-col :md="24" :lg="12" @click.native="handleZtClick('vryy')">
          <div class="zt-item">
            <el-image :src="require('../assets/image/vryy.png')" style="width: 100%; height: auto"></el-image>
            <button class="y-btn lan"><span>查看详情</span></button>
          </div>
        </el-col>
        <el-col :md="24" :lg="12" @click.native="handleZtClick('lszc')">
          <div class="zt-item">
            <el-image :src="require('../assets/image/lszl.png')" style="width: 100%; height: auto"></el-image>
            <button class="y-btn ju"><span>查看详情</span></button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="panel">
      <div class="flex-ac">
        <el-image lazy :src="require('../assets/image/zhanting.png')" class="title-img"></el-image>
        <span class="panel-title">新闻资讯</span>
      </div>
      <div class="xw-row">
        <el-row class="more" type="flex" justify="end">
          <router-link to="/news" class="y-btn"><span>更多</span></router-link>
        </el-row>
        <el-row class="xw-box" type="flex" :gutter="50">
          <el-col :md="12" :lg="8" class="xw-item">
            <router-link to="/news/blybh">
              <el-image class="hitem-img" lazy :src="require('../assets/image/xwzx1.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">找到点亮职业兴趣的明灯</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内， 后台活动最好一个月更新一次.</p>
            </router-link>
          </el-col>
          <el-col :md="12" :lg="8" class="xw-item">
            <router-link to="/news/zqjs">
              <el-image class="hitem-img" lazy :src="require('../assets/image/xwzx2.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">秘方密室</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月 更新一次.</p>
            </router-link>
          </el-col>
          <el-col :md="12" :lg="8" class="xw-item">
            <router-link to="/news/blybh">
              <el-image class="hitem-img" lazy :src="require('../assets/image/xwzx3.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">航天航空基地</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内。</p>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <!-- <div class="panel">
      <div class="flex-ac">
        <el-image lazy :src="require('../assets/image/zxhd.png')" class="title-img"></el-image>
        <span class="panel-title">最新活动</span>
      </div>
      <div class="xw-row">
        <el-row class="more" type="flex" justify="end">
          <router-link to="/activity" class="y-btn"><span>更多</span></router-link>
        </el-row>
        <el-row class="xw-box" type="flex" :gutter="50">
          <el-col :md="12" :lg="8" class="xw-item"> -->
            <!-- 对应文章的链接 -->
            <!-- /activity/某个文章的id -->
            <!-- <router-link to="/activity/blybh">
              <el-image lazy class="hitem-img" :src="require('../assets/image/xwzx4.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">Sandbox VR</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内， 后台活动最好一个月更新一次.</p>
            </router-link>
          </el-col>
          <el-col :md="12" :lg="8" class="xw-item">
            <router-link to="/activity/zqjs">
              <el-image lazy class="hitem-img" :src="require('../assets/image/xwzx5.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">IBM Watson Build</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内，后台活动最好一个月 更新一次.</p>
            </router-link>
          </el-col>
          <el-col :md="12" :lg="8" class="xw-item">
            <router-link to="/activity/blybh">
              <el-image lazy class="hitem-img" :src="require('../assets/image/xwzx6.png')" style="width: 100%; height: auto"></el-image>
              <p class="xw-item-title">探秘保时捷</p>
              <p class="xw-item-con">这是一段活动介绍，文字最好能控制到很少的字以内。</p>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isLoad: false,
      time: new Date()
    }
  },
  methods: {
    getVideo() {
      let date = new Date()
      let time = 1500 - (date - this.time)
      window.setTimeout(
        () => {
          this.isLoad = true
          this.$nextTick(() => {
            this.$refs.video.play()
          })
        },
        time > 0 ? time : 0
      )
    },
    handleZtClick(id) {
      this.$router.push({ path: `/showroom/${id}` })
    }
  }
}
</script>
<style lang="scss">
.home {
  padding-bottom: 180px;
}
.banner {
  border-radius: 0px 0px 20px 20px;
  position: relative;
  overflow: hidden;
  .banner-hide {
    visibility: hidden;
  }
  .banner-img-text {
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
  }
  .banner-video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    object-fit: unset;
  }
  .banner-video.load {
    z-index: 9;
  }
  .banner-con {
    width: 1400px;
    margin: 0 auto;
  }
  .banner-row {
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
  }
}
.panel {
  padding-top: 126px;
  width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-img {
    width: 130px;
  }
  .panel-title {
    color: #000;
    font-family: Source Han Sans CN;
    font-style: normal;
    font-size: 96px;
    font-weight: 900;
    line-height: normal;
    margin-left: 45px;
  }
  .zt-box {
    flex-wrap: wrap;
    margin-top: 124px;
  }
  .zt-item {
    cursor: pointer;
    position: relative;
    margin-bottom: 38px;
    .y-btn {
      position: absolute;
      left: 10%;
      bottom: 20%;
      &:hover {
        background-color: #000;
        span {
          color: #ed954f;
        }
        &.lan {
          span {
            color: #5bacea;
          }
        }
        &.ju {
          span {
            color: #ee7655;
          }
        }
      }
    }
  }

  .more {
    margin-top: 20px;
    padding-right: 35px;
    width: 100%;
    .y-btn {
      border-color: #7b71ff;
      &:hover {
        background-color: #7b71ff;
      }
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  .xw-box {
    flex-wrap: wrap;
    font-family: Source Han Sans CN;
    font-style: normal;
    line-height: normal;
    margin-top: 20px;
    .xw-item {
      .xw-item-title {
        color: #000;
        font-size: 36px;
        font-weight: 700;
        margin-top: 20px;
      }
      .xw-item-con {
        color: #797b83;
        font-size: 15px;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
}

.img-tips-xs {
  display: none;
}

@media screen and (max-width: 1480px) {
  .banner {
    .banner-con {
      width: 1200px;
    }
  }

  .panel {
    width: 1200px;
  }
}
@media screen and (max-width: 1280px) {
  .banner .banner-img-text {
    top: 20px;
  }
  .img-text {
    top: 20px;
  }
  .img-tips-xs {
    display: block;
  }
  .img-tips-lg {
    display: none;
  }
  .banner {
    .banner-con {
      width: 880px;
    }
  }

  .panel {
    width: 880px;
    padding-top: 76px;
    .title-img {
      width: 96px;
    }
    .zt-box {
      margin-top: 84px;
    }
    .zt-item {
      margin-bottom: 32px;
    }
    .panel-title {
      font-size: 80px;
    }

    .xw-item {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 960px) {
  .banner {
    .banner-row {
      margin-top: 30px;
    }
    .banner-con {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .panel {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    .xw-box .xw-item {
      &:last-child {
        margin-bottom: 0;
      }
      .xw-item-title {
        font-size: 28px;
      }
    }
    .xw-row {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .home {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 768px) {
  .home {
    .banner {
      margin-top: 40px;
      .banner-row {
        margin-top: 20px;
      }
      .banner-con {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
      .banner-img-text {
        top: 0;
      }
    }
  }

  .panel {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 36px;
    .more {
      padding-right: 15px;
    }
    .title-img {
      width: 56px;
    }
    .zt-box {
      margin-top: 30px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .zt-item {
      margin-bottom: 16px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .y-btn {
        padding: 4px;
        border-radius: 26px;
        min-width: 72px;
        bottom: 18%;
        span {
          font-size: 12px;
        }
      }
    }
    .panel-title {
      font-size: 40px;
      margin-left: 25px;
    }
    .xw-box {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .xw-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 12px;
      }
    }
    .more {
      .y-btn {
        padding: 4px;
        border-radius: 26px;
        min-width: 82px;
      }
    }
  }
}
</style>
