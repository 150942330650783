<template>
  <component :is="showroomDetail"></component>
</template>

<script>
import cszc from './showroom/details/cszc/index.vue'
import lszc from './showroom/details/lszc/index.vue'
import vryy from './showroom/details/vryy/index.vue'
export default {
  name: 'ShowroomDetail',
  data() {
    return {}
  },
  components: {
    cszc,
    lszc,
    vryy
  },
  computed: {
    showroomDetail() {
      return this.$route.params.type
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss">
.zc {
  .zc-con {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .zc-con-title {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      margin: 70px 0 60px;
      text-align: center;
    }
    .zc-con-text {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 20px 0 40px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .zc {
    .zc-con {
      width: 100%;
      padding: 30px 60px 60px;
    }
  }
}
@media screen and (max-width: 960px) {
  .zc {
    .zc-con {
      padding: 80px 40px;
      .zc-con-title {
        font-size: 38px;
        margin: 60px 0 40px;
      }
      .zc-con-text {
        font-size: 18px;
        padding: 16px 0 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .zc {
    .zc-con {
      padding: 20px 20px;
      .zc-con-title {
        font-size: 26px;
        margin: 30px 0 20px;
      }
      .zc-con-text {
        font-size: 14px;
        padding: 10px 0 20px;
      }
    }
  }
}
</style>
