<template>
  <div class="zc">
    <div class="zc-top">
      <el-image lazy :src="require('../../assets/image/showroom/cszc-bj.png')" style="width: 100%; height: auto">
        <div slot="placeholder" class="image-slot" style="height: 48vw">
          <i class="el-icon-loading"></i>
        </div>
      </el-image>
      <div class="title-box">
        <el-image lazy class="title" :src="require('../../assets/image/showroom/cszc-title.png')">
          <div slot="placeholder" class="image-slot" style="height: 100%">
            <i class="el-icon-loading"></i>
          </div>
        </el-image>
      </div>
    </div>
    <div class="zc-list">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/showroom' }">展陈列表</el-breadcrumb-item>
        <el-breadcrumb-item>常设展陈</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row class="zc-list-box" type="flex" justify="center" style="flex-wrap: wrap">
        <el-col class="zc-item" :col="24" v-for="item in zcList" :key="item.id" @click.native="handleZcClick(item)">
          <el-image lazy class="hitem-img" :src="require(`../../assets/image/showroom/${item.img}.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true">
            <div slot="placeholder" class="image-slot">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <el-image class="zc-item-text" lazy :src="require(`../../assets/image/showroom/${item.img}-text.png`)" style="width: 100%; height: auto" @load="item.loadingImg = true"></el-image>
          <!-- <button class="y-btn" v-if="item.loadingImg"><span>详情</span></button> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cszc',
  data() {
    return {
      zcList: [
        {
          id: 'sklx',
          img: 'cszc-item1',
          loadingImg: false
        },
        {
          id: 'slpd',
          img: 'cszc-item2',
          loadingImg: false
        },
        {
          id: 'dxmf',
          img: 'cszc-item3',
          loadingImg: false
        },
        {
          id: 'hsgc',
          img: 'cszc-item4',
          loadingImg: false
        },
        {
          id: 'mrzy',
          img: 'cszc-item5',
          loadingImg: false
        },
        {
          id: 'jsxs',
          img: 'cszc-item6',
          loadingImg: false
        },
        {
          id: 'wxdd',
          img: 'cszc-item7',
          loadingImg: false
        },
        {
          id: 'cjsjc',
          img: 'cszc-item8',
          loadingImg: false
        },
        {
          id: 'ysjmx',
          img: 'cszc-item9',
          loadingImg: false
        },
        {
          id: 'cqzs',
          img: 'cszc-item10',
          loadingImg: false
        },
        {
          id: 'ljf',
          img: 'cszc-item11',
          loadingImg: false
        }
      ]
    }
  },
  computed: {},
  mounted() {},
  methods: {
    // handleZcClick(item) {
    //   this.$router.push({ name: 'showroomDetail', params: { id: item.id, type: 'cszc' } })
    // }
  }
}
</script>
<style lang="scss"></style>
