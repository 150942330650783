<template>
  <div class="a-detail">
    <p class="title">商汤科技</p>
    <p class="subtitle">硬核科技</p>
    <div class="content">
      <div style="border-radius: 20px; background: #3aaa68; height: 403px; display: flex; align-items: center; justify-content: center">
        <p style="color: #fff; font-family: Source Han Sans CN; font-size: 48px; font-style: normal; font-weight: 900; line-height: normal">这个是行程安排</p>
      </div>
      <h2 class="zc-con-title">活动现场照片</h2>
      <el-row class="hd-zp" :gutter="26">
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/activityDetail/hdzp1.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/activityDetail/hdzp2.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/activityDetail/hdzp3.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
        <el-col :md="24" :lg="12" class="hd-zp-item">
          <el-image lazy :src="require('../../assets/image/activityDetail/hdzp4.png')" style="width: 100%; height: auto; margin-bottom: 26px"></el-image>
          <el-image lazy :src="require('../../assets/image/activityDetail/hdzp5.png')" style="width: 100%; height: auto"></el-image>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blybh',
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss"></style>
